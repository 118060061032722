import axios from "axios";
import {host} from "@/store/config";

export default {
    async getProducts(params){
        return await axios.get(host+'/products', {
            params: params
        })
    },
    async getProductData(id) {
        return await axios.get(host + '/products', {
            params: {
                id: id,
            }
        })
    },
    async getBrands() {
      return await axios.get(host + '/brand')
    },

    async createOrEditProduct(data) {
        return await axios.put(host + '/product', data)
    },
    async deleteProduct(uuid) {
        return await axios.delete(host + '/product', {data: {
            id: uuid,
        }})
    },
    async createOrEditBrand(brand) {
        return await axios.put(host + '/brand', null, {
            params: {
                id: brand.id,
                brand: brand.name,
            }
        })
    },
    async deleteBrand(id) {
        return await axios.delete(host + '/brand', {
            params: {
                id: id,
            }
        })
    },

}